const anafi = {
    title: 'Anafi',
    seoDescription:`Anafi – a small island on the edge of the Cyclades. A rock that competes
    that of Gibraltar in magnificence. A beautiful Chora, open to sea and sky.`,
    submit: 'Submit',
    headerTitle: 'Anafi is a definite surprise.',
    headerSubtitle: `According to Mythology, Anafi surfaced from the depths of the sea, following
    the command of God Apollo, in order to offer refuge to the Argonauts, in the
    middle of a great storm.`,
    paragraph1: `Anafi – a small island on the edge of the Cyclades. A rock that competes
  that of Gibraltar in magnificence. A beautiful Chora, open to sea and sky.
  Beautiful people and a unique energy felt all around. Green-blue waters,
  golden beaches, mountain masses, white domed houses, lovely smells,
  authentic smiles… A unique combination of unexpected calmness and frantic
  partying until the break of dawn. Anafi is a definite surprise.`,
    paragraph2: `According to Mythology, Anafi surfaced from the depths of the sea, following
  the command of God Apollo, in order to offer refuge to the Argonauts, in the
  middle of a great storm. This is where its name is derived from, having
  remained unchanged since the ancient times. The same applies to its
  untouched distinct energy, which for many years has captivated visitors and
  turned them into its loyal frequenters and lovers…`,
    paragraph3: `The “sacred” rock constitutes the absolute landmark of the island, while the
  beaches, most of which face South, make up for an ideal combination for
  every visitor. Some small and secluded like Katsouni and Agioi Anargyroi,
  and others long and easily accessible, like the famous Roukounas and
  Kleissidi – they all share amazing clear waters and soft sandy beaches.`,
    paragraph4: `Anafi offers a unique experience that is difficult to put into words. One
  thing is certain though, Anafi is a special destination that makes you want
  to return again and again.`,
}

export default anafi
