const links = {
    home: 'Αρχική',
    restaurant: 'Εστιατόριο',
    rooms: 'Δωμάτιο',
    anafi: 'Ανάφη',
    contact: 'Επικοινωνία',
    makria: 'Μακριά',
    pahia: 'Παχιά',
    ftena: 'Φτενά',
}
export default links
