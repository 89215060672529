const anafi = {
    title: 'Ανάφη',
    seoDescription: `Ανάφη – ένα μικρό νησί στην άκρη των Κυκλάδων. Ένας βράχος που συναγωνίζεται
  σε μεγαλοπρέπεια αυτόν του Γιβραλτάρ. Μια όμορφη Χώρα ανοιχτή σε θάλασσα κι
  ουρανό. Ωραίοι άνθρωποι και μια ενέργεια μοναδική.`,
    submit: 'Submit',
    headerTitle: 'Η Ανάφη είναι μια έκπληξη.',
    headerSubtitle: `Ο μύθος τη θέλει να εμφανίζεται από το βυθό της θάλασσας με εντολή του θεού
  Απόλλωνα για να προσφέρει καταφύγιο στους Αργοναύτες εν μέσω μιας ισχυρής
  καταιγίδας.`,
    paragraph1: `Ανάφη – ένα μικρό νησί στην άκρη των Κυκλάδων. Ένας βράχος που συναγωνίζεται
  σε μεγαλοπρέπεια αυτόν του Γιβραλτάρ. Μια όμορφη Χώρα ανοιχτή σε θάλασσα κι
  ουρανό. Ωραίοι άνθρωποι και μια ενέργεια μοναδική. Γαλαζοπράσινα νερά,
  χρυσές αμμουδιές, ορεινοί όγκοι, λευκά θολωτά σπίτια, όμορφες μυρωδιές,
  αυθεντικά χαμόγελα… Ένας μοναδικός συνδυασμός απόκοσμης ηρεμίας και ξέφρενου
  γλεντιού μέχρι το ξημέρωμα. Η Ανάφη είναι μια έκπληξη.`,
    paragraph2: `Ο μύθος τη θέλει να εμφανίζεται από το βυθό της θάλασσας με εντολή του θεού
  Απόλλωνα για να προσφέρει καταφύγιο στους Αργοναύτες εν μέσω μιας ισχυρής
  καταιγίδας. Από εκεί προκύπτει και το όνομά της «Ανάφη» (αναφαίνεται), το
  οποίο έχει μείνει αναλλοίωτο από την αρχαιότητα. Αναλλοίωτη έχει μείνει και
  η ιδιαίτερη ενέργειά της εδώ και χρόνια που αιχμαλωτίζει τον επισκέπτη και
  τον μετατρέπει σε θαμώνα και εραστή της…`,
    paragraph3: `Ο «ιερός» βράχος της αποτελεί το σήμα κατατεθέν της. Οι παραλίες της
  στραμμένες στο νότο αποτελούν τον ιδανικό συνδυασμό – άλλες μικρές και πιο
  απομονωμένες, όπως το Κατσούνι και οι Άγιοι Ανάργυροι, κι άλλες μεγαλύτερες
  και εύκολα προσβάσιμες, όπως ο διάσημος Ρούκουνας και το Κλεισίδι, έχουν σαν
  κοινό στοιχείο τα υπέροχα νερά τους και τις απολαυστικές αμμουδιές.`,
    paragraph4: `Η Ανάφη αποτελεί μια μοναδική εμπειρία που δύσκολα περιγράφεται με λέξεις.
  Το σίγουρο είναι ότι αποτελεί έναν ιδιαίτερο προορισμό, στον οποίο θες να
  επιστρέφεις ξανά και ξανά.`,
}

export default anafi
