const links = {
  home: 'Home',
  restaurant: 'Restaurant',
  rooms: 'Rooms',
  anafi: "Anafi",
  contact: "Contact",
  makria: 'Makria',
  pahia: 'Pahia',
  ftena: 'Ftena',
}

export default links
