const contact = {
    title: 'Επικοινωνήστε μαζί μας',
    subtitle:  "Ρωτήστε μας για τη διαθεσιμότητα των δωματίων μας ή το εστιατόριο.",
    submit: 'Αποστολή',
    name: "Όνομα",
    message:'Μήνυμα',
    address: "Διεύθυνση",
    phone: "Τηλέφωνο", 
    email: "email",

}

export default contact
