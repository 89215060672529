const roomCard = {
  title: 'Makria',
  bed: 'bed',
  recomended: 'recommended',
  capacity: 'capacity',
  capacityContent: '2 persons',
  bedContent: 'King bed',
  recomendedContent: 'great for relax',
  viewContent: 'sea view'
}

export default roomCard
