const restaurant = {
  title: 'Restaurant',
  seoDescription:`The
  restaurant that for over 20 years has been defining the gastronomic profile
  of the island, combines excellent tastes with live music. Armenaki has put
  Anafi on the map, becoming a point of reference for many visitors.`,
  headingTitle: 'In the heart of Chora, where everything happens, you find Armenaki.',
  headingSubtitle: 'Armenaki has put Anafi on the map, becoming a point of reference for many visitors.',
  paragraph1: `In the heart of Chora, where everything happens, you find Armenaki. The
  restaurant that for over 20 years has been defining the gastronomic profile
  of the island, combines excellent tastes with live music. Armenaki has put
  Anafi on the map, becoming a point of reference for many visitors.`,
  paragraph2: `Open from the morning until the early hours, Armenaki offers late breakfast
  and delicious dishes for a peaceful lunch during siesta time. In the
  evening, the ambience changes in the sounds of live music and everybody
  blends into a big group of friends.`,
  paragraph3: `Since 2020, Armenaki has also been offering a choice for your stay with
  Armenaki Katoikies. Two levels below the restaurant, the three studios that
  have been completed with lots of love and personal work offer the ideal
  choice for the guest who wants to be close to the action without sacrificing
  their peace and relaxation in their personal space.`,
  paragraph4: `With their balconies facing south-west and with breathtaking view of the
  sea, the names of Katoikies were inspired by the three small uninhabited
  islands just in the south of Anafi. “Makria” (Long), “Pachia” (Thick) and
  “Ftena” (Slim) constitute the new, intriguing alternative for your stay in
  Chora.`,
}

export default restaurant
