const contact = {
    title: 'Contact us',
    subtitle: "We'd love to hear from you",
    submit: 'Submit',
    name: 'Name',
    message: 'Message',
    address: 'Address',
    phone: 'Phone',
    email: 'Email',
}

export default contact
