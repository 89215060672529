const room = {
  moreTitle: 'Our Rooms',
  heroTitle: 'Welcome to ',
  submit: 'Submit',
  descriptionTitle: 'Minimalistic aesthetic – Exceptional view - The absolute experience!',
  sq: 'sq.m',
  bed:'King Bed',
  guests: 'Guests',
  bathroom: 'Bathroom with shower'
}

export default room
