const room = {
  moreTitle: 'Δωμάτια',
  heroTitle: '',
  submit: 'Submit',
  descriptionTitle: 'Μίνιμαλ Αισθητική – Μοναδική Θέα – Απόλυτη Εμπειρία!',
  sq: 'τ.μ.',
  bed:'Διπλό κρεβάτι',
  guests: 'Επισκέπτες',
  bathroom: 'Μπάνιο με ντούς'
}

export default room
