import homepage from './homepage'
import room from './room'
import contact from './contact'
import error404 from './error404'
import anafi from './anafi'
import restaurant from './restaurant'
import links from './links'
import roomCard from './roomCard'

const en = {
    homepage,
    room,
    contact,
    error404,
    anafi,
    restaurant,
    links,
    roomCard,
}

export default en
