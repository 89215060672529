const restaurant = {
  title: 'Εστιατόριο',
  seoDescription: `Μέχρι
  πέρυσι το εστιατόριο που τα τελευταία 20 χρόνια άλλαξε το γαστρονομικό
  προφίλ του νησιού, συνδυάζοντας εξαιρετικές γεύσεις με ζωντανή μουσική,
  έβαλε στον ταξιδιωτικό χάρτη την Ανάφη και έγινε σημείο αναφοράς για πολλούς
  επισκέπτες.`,
  headingTitle: 'Σε ένα από τα πιο κεντρικά σημεία της Χώρας, βρίσκεται το Αρμενάκι.',
  headingSubtitle: 'Από το 2020, το Αρμενάκι αποτελεί και επιλογή για διαμονή, αφού λειτουργούν πλέον οι Αρμενάκι Κατοικίες.',
  paragraph1: `Σε ένα από τα πιο κεντρικά σημεία της Χώρας, εκεί που χτυπάει η καρδιά της
  Ανάφης πρωί – βράδυ και με εξαιρετική θέα βρίσκεται το Αρμενάκι. Μέχρι
  πέρυσι το εστιατόριο που τα τελευταία 20 χρόνια άλλαξε το γαστρονομικό
  προφίλ του νησιού, συνδυάζοντας εξαιρετικές γεύσεις με ζωντανή μουσική,
  έβαλε στον ταξιδιωτικό χάρτη την Ανάφη και έγινε σημείο αναφοράς για πολλούς
  επισκέπτες.`,
  paragraph2: `Ανοιχτό από το πρωί μέχρι τις πρώτες πρωινές ώρες, προσφέρει πρωινό μέχρι
  αργά το μεσημέρι, απολαυστικά πιάτα για ένα ήρεμο γεύμα όταν η Χώρα παίρνει
  τη σιέστα της, ενώ το βράδυ αλλάζει πρόσωπο υπό τους ήχους της ζωντανής
  μουσικής και κάνει όλα τα τραπέζια να γίνονται μια μεγάλη παρέα.`,
  paragraph3: `Από το 2020, το Αρμενάκι αποτελεί και επιλογή για διαμονή, αφού λειτουργούν
  πλέον οι Αρμενάκι Κατοικίες. Δύο επίπεδα κάτω από το εστιατόριο, τα τρία
  δωμάτια – studio που ολοκληρώθηκαν με πολύ μεράκι και προσωπική εργασία
  προσφέρουν την ιδανική επιλογή για τον επισκέπτη που θέλει να βρίσκεται
  κοντά σε όλα, χωρίς να θυσιάζει την ηρεμία στον προσωπικό του χώρο.`,
  paragraph4: `Με τα μπαλκόνια τους στραμμένα νοτιοδυτικά και απεριόριστη θέα στη θάλασσα,
  οι Κατοικίες πήραν το όνομα τους από τα τρία νησάκια που βρίσκονται νότια
  της Ανάφης. Η Μακριά, η Παχιά και τα Φτενά – ονόματα που περιγράφουν τη
  μορφολογία των ακατοίκητων μικρών νησιών – είναι η νέα επιλογή για διαμονή
  στη Χώρα…`,
}

export default restaurant
