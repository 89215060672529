const roomCard = {
    title: 'Μακριά',
    bed: 'Κρεβάτι',
    recomended: 'Συνίσταται',
    capacity: 'Επισκέπτες',
    capacityContent: '2 άτομα',
    bedContent: 'Διπλό κρεβάτι',
    recomendedContent: 'Τέλειο για χαλάρωση',
    viewContent: 'Θάλασσα'
}

export default roomCard
